<template>
  <v-dialog
    :value="showArcGisLoginFailureDialog"
    max-width="600"
    close
    persistent
  >
    <v-card>
      <v-card-text class="pa-5">
        {{ error }}
      </v-card-text>
      <v-card-actions class="pa-5 d-flex justify-end">
        <v-btn color="primary" @click="$emit('close')"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ArcGisLoginFailureDialog",
  props: {
    showArcGisLoginFailureDialog: Boolean,
    error: String,
  },
};
</script>
