<template>
  <v-dialog :value="showCreateNewPasswordDialog" max-width="500px" persistent>
    <v-card>
      <validation-observer ref="createNewPasswordForm" v-slot="{ passed }">
        <form @submit.prevent="createNewPassword">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title>Create New Password</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('reset-password-dialog-close')">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pb-0">
            <p>Enter and confirm your password</p>
            <validation-provider
              :bails="false"
              v-slot="{ errors, valid, failedRules }"
              name="Password"
              :rules="{
                hasAtLeastOneUpperChar: true,
                hasAtLeastOneLowerChar: true,
                hasAtLeastOneNumber: true,
                required: true,
                passwordMin: { length: 8 },
              }"
            >
              <v-text-field
                v-model="password"
                label="Password *"
                hide-details="auto"
                :success="valid"
                :error="errors.length > 0"
                name="password"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :append-icon="showPassword ? mdiEyeOff : mdiEye"
              >
                <template #prepend>
                  <v-icon v-if="valid" color="rgba(32, 150, 13, 0.76)">
                    {{ mdiLock }}
                  </v-icon>
                  <v-icon v-else>
                    {{ mdiLock }}
                  </v-icon>
                </template>
              </v-text-field>

              <template>
                <div class="px-11">
                  <v-row class="pt-2">
                    <v-col
                      cols="6"
                      v-for="rule of getValidRules(failedRules)"
                      :key="rule.msg"
                      class="caption d-flex pa-0"
                    >
                      <v-icon
                        v-if="rule.status === true && password.length > 0"
                        small
                        color="rgba(32, 150, 13, 0.76)"
                      >
                        {{ mdiCheck }}
                      </v-icon>
                      <v-icon v-else small class="pr-1">{{ mdiMinus }}</v-icon>
                      <div
                        :class="{
                          validRules: rule.status && password.length > 0,
                          invalidRules: !rule.status || password.length === 0,
                        }"
                        class="font-weight-medium"
                      >
                        {{ rule.msg }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              :bails="false"
              name="confirmPassword"
              :rules="{
                passwordsMatch: '@Password',
                required: true,
              }"
            >
              <v-text-field
                class="pt-4"
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? mdiEyeOff : mdiEye"
                label="Confirm Password *"
                hide-details="auto"
                :success="valid"
                :error="errors.length > 0"
                name="confirmPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
              >
                <template #prepend>
                  <v-icon v-if="valid" color="rgba(32, 150, 13, 0.76)">
                    {{ mdiLock }}
                  </v-icon>
                  <v-icon v-else>
                    {{ mdiLock }}
                  </v-icon>
                </template>
              </v-text-field>

              <template>
                <div
                  v-if="valid && confirmPassword.length > 0"
                  :key="msg"
                  class="caption validRules pt-2 px-9"
                >
                  <v-icon small color="rgba(32, 150, 13, 0.76)">
                    {{ mdiCheck }}
                  </v-icon>
                  Passwords match
                </div>
                <div
                  v-else
                  class="caption invalidRules pt-2 px-8 font-weight-medium"
                >
                  <v-icon x-small color="#757575">{{ mdiMinus }}</v-icon>
                  Passwords match
                </div>
              </template>
            </validation-provider>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pt-n3 pb-3 pr-3">
            <v-btn
              color="primary"
              type="submit"
              :dark="passed"
              :disabled="!passed"
            >
              Reset Password
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiMinus,
  mdiLock,
  mdiEye,
  mdiEyeOff,
  mdiCheck,
} from "@mdi/js";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;
const RULES = [
  "hasAtLeastOneUpperChar",
  "hasAtLeastOneNumber",
  "hasAtLeastOneLowerChar",
  "passwordMin",
];

const PASSWORD_RULES = {
  hasAtLeastOneUpperChar: "One uppercase letter",
  hasAtLeastOneNumber: "One number",
  hasAtLeastOneLowerChar: "One lowercase letter",
  passwordMin: "Eight characters minimum",
};

export default {
  name: "CreateNewPasswordDialog",
  props: {
    showCreateNewPasswordDialog: Boolean,
  },
  data() {
    return {
      mdiClose,
      mdiMinus,
      mdiLock,
      mdiEye,
      mdiEyeOff,
      mdiCheck,
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      rules: {},
    };
  },
  methods: {
    getValidRules(failedRules = {}) {
      const passedRules = RULES.filter(
        (r) => !Object.keys(failedRules).includes(r)
      );
      const rules = [
        ...RULES.map((r) => {
          return { msg: PASSWORD_RULES[r] };
        }),
      ];
      if (passedRules.includes("hasAtLeastOneUpperChar")) {
        const index = rules.findIndex((r) => r.msg === "One uppercase letter");
        rules[index].status = true;
      } else {
        const index = rules.findIndex((r) => r.msg === "One uppercase letter");
        rules[index].status = false;
      }
      if (passedRules.includes("hasAtLeastOneNumber")) {
        const index = rules.findIndex((r) => r.msg === "One number");
        rules[index].status = true;
      } else {
        const index = rules.findIndex((r) => r.msg === "One number");
        rules[index].status = false;
      }
      if (passedRules.includes("hasAtLeastOneLowerChar")) {
        const index = rules.findIndex((r) => r.msg === "One lowercase letter");
        rules[index].status = true;
      } else {
        const index = rules.findIndex((r) => r.msg === "One lowercase letter");
        rules[index].status = false;
      }
      if (passedRules.includes("passwordMin")) {
        const index = rules.findIndex(
          (r) => r.msg === "Eight characters minimum"
        );
        rules[index].status = true;
      } else {
        const index = rules.findIndex(
          (r) => r.msg === "Eight characters minimum"
        );
        rules[index].status = false;
      }
      this.rules = rules;
      return rules;
    },
    async createNewPassword() {
      const success = await this.$refs.createNewPasswordForm.validate();
      if (!success) {
        return;
      }
      const { password } = this;
      const { token } = this.$route.query;
      await axios.post(`${APIURL}/users/reset_password`, {
        password,
        token,
      });
      this.$emit("password-reset");
    },
  },
};
</script>

<style scoped>
.validRules {
  color: rgba(32, 150, 13, 0.76);
}

.invalidRules {
  color: #757575;
}

.v-application .error--text {
  color: #757575 !important;
  caret-color: #757575 !important;
}

.v-application .primary--text {
  color: #757575 !important;
  caret-color: #757575 !important;
}
</style>
