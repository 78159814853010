<template>
  <div>
    <p class="caption">Layer Info</p>

    <v-card id="layerInfo">
      <v-card-text>
        <p class="caption mb-0 pb-0">Layer Type</p>
        <p>{{ selectedLayer.service_type | layerType }}</p>

        <validation-observer ref="layerForm">
          <form>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Layer Name"
              rules="required"
            >
              <v-text-field
                v-model="selectedLayer.service_name"
                label="Layer Name *"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="serviceName"
                @change="$emit('layer-changed', selectedLayer)"
                :disabled="!canManageLayers"
              />
            </validation-provider>

            <div style="width: 40%">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Reference Field"
                v-if="['U', 'F'].includes(selectedLayer.service_type)"
              >
                <v-select
                  autocomplete="off"
                  clearable
                  v-model="selectedLayer.ref_field"
                  label="Reference Field"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="refField"
                  item-text="label"
                  item-value="value"
                  :items="gisDataFieldChoices"
                  @change="$emit('layer-changed', selectedLayer)"
                  :disabled="!canManageLayers"
                >
                </v-select>
              </validation-provider>
            </div>

            <div>
              <section class="my-2">
                <v-switch
                  class="my-0"
                  v-if="['U', 'F'].includes(selectedLayer.service_type)"
                  v-model="selectedLayer.stormwater_enabled"
                  label="Stormwater Enabled"
                  @change="$emit('layer-changed', selectedLayer)"
                  :disabled="!canManageLayers"
                  hide-details
                ></v-switch>
              </section>

              <section class="my-2">
                <v-switch
                  class="my-0"
                  v-if="['U', 'F'].includes(selectedLayer.service_type)"
                  v-model="selectedLayer.cache_gis_data_values"
                  label="Download Layer Data"
                  @change="$emit('layer-changed', selectedLayer)"
                  :disabled="!canManageLayers"
                  hide-details
                ></v-switch>
              </section>

              <section class="d-flex align-center mt-2">
                <v-switch
                  class="my-0"
                  v-if="['U', 'F'].includes(selectedLayer.service_type)"
                  v-model="selectedLayer.site_enabled"
                  label="Site Enabled"
                  @change="$emit('layer-changed', selectedLayer)"
                  :disabled="!canManageLayers"
                  hide-details
                ></v-switch>

                <v-tooltip
                  right
                  max-width="300px"
                  open-on-click
                  :open-on-hover="false"
                  :open-on-focus="false"
                  v-model="showSiteEnabledTooltip"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      v-click-outside="onClickOutside"
                      @click="
                        closeAllTooltips();
                        showSiteEnabledTooltip = true;
                      "
                    >
                      <v-icon>{{ mdiInformation }}</v-icon>
                    </v-btn>
                  </template>

                  <div>
                    <div>
                      Turn on to make this layer site enabled. With a site
                      enabled layer you can:
                    </div>
                    <ul>
                      <li>Set site name and address</li>
                      <li>Add site contacts</li>
                      <li>Create public link</li>
                      <li>Create site key (use to share site via key)</li>
                    </ul>
                  </div>
                </v-tooltip>
              </section>

              <section
                class="d-flex align-center w-40"
                style="margin-left: 47px"
                v-if="selectedLayer.site_enabled"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Site Name Field"
                  rules="required"
                >
                  <v-select
                    v-model="selectedLayer.site_name_field"
                    label="Site Name Field"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="siteNameField"
                    item-text="label"
                    item-value="value"
                    :items="allDataFieldChoices"
                    @change="$emit('layer-changed', selectedLayer)"
                    :disabled="!canManageLayers"
                  />
                </validation-provider>

                <v-tooltip
                  right
                  max-width="300px"
                  open-on-click
                  :open-on-hover="false"
                  :open-on-focus="false"
                  v-model="showNameFieldTooltip"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      v-click-outside="onClickOutside"
                      @click="
                        closeAllTooltips();
                        showNameFieldTooltip = true;
                      "
                    >
                      <v-icon>{{ mdiInformation }}</v-icon>
                    </v-btn>
                  </template>

                  <div>
                    Specify the UtiliSync or GIS field that you want to use for
                    the site name.
                  </div>
                </v-tooltip>
              </section>

              <section
                class="d-flex align-center w-40"
                style="margin-left: 47px"
                v-if="selectedLayer.site_enabled"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Site Address Field"
                >
                  <v-select
                    v-model="selectedLayer.site_address_field"
                    label="Site Address Field"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="siteAddressField"
                    item-text="label"
                    item-value="value"
                    clearable
                    :items="allDataFieldChoices"
                    @change="$emit('layer-changed', selectedLayer)"
                    :disabled="!canManageLayers"
                  />
                </validation-provider>

                <v-tooltip
                  right
                  max-width="300px"
                  open-on-click
                  :open-on-hover="false"
                  :open-on-focus="false"
                  v-model="showAddressFieldTooltip"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      v-click-outside="onClickOutside"
                      @click="
                        closeAllTooltips();
                        showAddressFieldTooltip = true;
                      "
                    >
                      <v-icon>{{ mdiInformation }}</v-icon>
                    </v-btn>
                  </template>

                  <div>
                    Specify the UtiliSync or GIS field that you want to use for
                    the site address.
                  </div>
                </v-tooltip>
              </section>

              <section
                class="d-flex align-center my-2"
                style="margin-left: 47px"
                v-if="selectedLayer.site_enabled"
              >
                <v-switch
                  class="my-0"
                  v-if="['U', 'F'].includes(selectedLayer.service_type)"
                  v-model="selectedLayer.auto_generate_short_site_key"
                  label="Auto Generate Site Key"
                  @change="$emit('layer-changed', selectedLayer)"
                  :disabled="!canManageLayers"
                  hide-details
                ></v-switch>

                <v-tooltip
                  right
                  max-width="300px"
                  open-on-click
                  :open-on-hover="false"
                  :open-on-focus="false"
                  v-model="showAutoGenerateSiteKeyTooltip"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      v-click-outside="onClickOutside"
                      @click="
                        closeAllTooltips();
                        showAutoGenerateSiteKeyTooltip = true;
                      "
                    >
                      <v-icon>{{ mdiInformation }}</v-icon>
                    </v-btn>
                  </template>

                  <div>
                    Turn this option on to automatically generate a site key for
                    all existing sites on this layer and create a site key for
                    all new sites created on this layer.
                  </div>
                </v-tooltip>
              </section>
            </div>

            <section>
              <div class="caption">Show Related Tab Only</div>

              <v-switch
                v-if="['U', 'F'].includes(selectedLayer.service_type)"
                v-model="selectedLayer.show_related_tab_only_inspect"
                label="Inspect App"
                @change="$emit('layer-changed', selectedLayer)"
                :disabled="!canManageLayers"
                hide-details
              ></v-switch>

              <v-switch
                v-if="['U', 'F'].includes(selectedLayer.service_type)"
                v-model="selectedLayer.show_related_tab_only_sites"
                label="Sites App"
                @change="$emit('layer-changed', selectedLayer)"
                :disabled="!canManageLayers"
                hide-details
              ></v-switch>
            </section>

            <validation-provider
              v-slot="{ errors, valid }"
              name="811 Code"
              v-if="['L'].includes(selectedLayer.service_type)"
            >
              <v-select
                autocomplete="off"
                label="811 Code"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="account811"
                :items="account811Choices"
                item-text="label"
                item-value="value"
                v-model="selectedLayer.locate_request_provider_account_id"
                @change="$emit('layer-changed', selectedLayer)"
                :disabled="!canManageLayers"
              >
              </v-select>
            </validation-provider>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import permissionsMixin from "@/mixins/permissionsMixin";
import { mapGetters, mapMutations } from "vuex";
import { mdiInformation } from "@mdi/js";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "LayerInfoForm",
  props: {
    layer: Object,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      selectedLayer: {},
      gisDataFields: [],
      accounts811: [],
      featureServiceGisFields: [],
      mdiInformation,
      showSiteEnabledTooltip: false,
      showNameFieldTooltip: false,
      showAddressFieldTooltip: false,
      showAutoGenerateSiteKeyTooltip: false,
    };
  },
  async beforeMount() {
    await this.getGisDataFields();
    await this.getFeatureServiceFields();
    await this.get811Accounts();
  },
  computed: {
    ...mapGetters(["utiliSyncFieldsUpdated"]),
    allDataFieldChoices() {
      const featureServiceGisFieldsChoices = this.featureServiceGisFields.map(
        (f) => {
          const { name, alias } = f;
          return {
            label: alias || name,
            value: alias || name,
          };
        }
      );

      const gisDataFieldsChoices = this.gisDataFields.map((f) => {
        const { name } = f;
        return {
          value: name,
          label: name,
        };
      });
      return [...featureServiceGisFieldsChoices, ...gisDataFieldsChoices];
    },
    gisDataFieldChoices() {
      if (this.layer.service_type === "F") {
        return this.featureServiceGisFields.map((f) => {
          const { name, alias } = f;
          return {
            label: alias || name,
            value: name,
          };
        });
      }
      return this.gisDataFields.map((f) => {
        const { gis_data_field_id: gisDataFieldId, name } = f;
        return {
          value: gisDataFieldId,
          label: name,
        };
      });
    },
    account811Choices() {
      const accounts811Options = this.accounts811.map((a) => {
        const {
          locate_request_provider_account_id: locateRequestProviderAccountId,
          label,
        } = a;
        return {
          label,
          value: locateRequestProviderAccountId,
        };
      });

      const showAll = {
        label: "Show All",
        value: null,
      };
      return [...accounts811Options, showAll];
    },
  },
  methods: {
    async onClickOutside(e) {
      await this.$nextTick();
      if (e.target.nodeName === "svg" || e.target.nodeName === "path") {
        return;
      }
      this.closeAllTooltips();
    },
    closeAllTooltips() {
      this.showSiteEnabledTooltip = false;
      this.showNameFieldTooltip = false;
      this.showAddressFieldTooltip = false;
      this.showAutoGenerateSiteKeyTooltip = false;
    },
    async getFeatureServiceFields() {
      const { map_service_id: mapServiceId } = this.layer;
      const {
        data: {
          results: { service_url: featureServiceUrl, token_type: tokenType },
        },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      const token =
        tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
      if (!featureServiceUrl) {
        return;
      }
      try {
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields = [] },
        } = await axios.get(featureServiceUrl, { params });
        this.featureServiceGisFields = fields;
      } catch (error) {
        console.warn(error);
      }
    },
    async getGisDataFields() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/gis_data_fields`, {
        params: {
          map_service_id: this.layer.map_service_id,
        },
      });
      this.gisDataFields = results;
    },
    async get811Accounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_provider_accounts/all`);
      this.accounts811 = results;
    },
    ...mapMutations(["setUtiliSyncFieldsUpdated"]),
  },
  watch: {
    layer: {
      immediate: true,
      deep: true,
      handler(val) {
        this.selectedLayer = cloneDeep(val);
      },
    },
    utiliSyncFieldsUpdated: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getGisDataFields();
          this.setUtiliSyncFieldsUpdated(false);
        }
      },
    },
  },
};
</script>
