<template>
  <v-dialog :value="showForgotPasswordDialog" max-width="500px" persistent>
    <v-card id="forgotPassswordForm">
      <validation-observer ref="forgotPassswordForm">
        <form @submit.prevent="resetPassword">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title
              ><div>Forgot Password</div>
              <div class="text-subtitle-2">
                Input UtiliSync Account Username
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('forgot-password-dialog-close')">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div class="pb-4">
              <strong>For reset of UtiliSync account passwords only</strong>.
              Contact your GIS administrator if you have forgotten your ArcGIS
              password.
            </div>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Username"
              rules="required"
            >
              <v-text-field
                v-model="username"
                :prepend-icon="mdiAccount"
                label="Username *"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="rgba(32, 150, 13, 0.76)"
                name="username"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pb-3 pr-3">
            <v-btn
              color="primary"
              type="button"
              text
              @click="showChat"
              id="forgot-username"
            >
              Forgot Username
            </v-btn>
            <v-btn color="primary" type="submit" dark id="reset-password"
              >Reset Password</v-btn
            >
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiAccount } from "@mdi/js";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "ForgotPasswordDialog",
  props: {
    showForgotPasswordDialog: Boolean,
  },
  data() {
    return {
      mdiClose,
      mdiAccount,
      username: "",
    };
  },
  methods: {
    async resetPassword() {
      const success = await this.$refs.forgotPassswordForm.validate();
      if (!success) {
        return;
      }
      const { username } = this;
      await axios.post(`${APIURL}/users/forgot_password`, {
        username,
        app: "user-admin",
      });
      this.$emit("password-reset-email-sent");
    },
    showChat() {
      if (!navigator.onLine) {
        return;
      }
      const subject = "Forgot Username";
      const text = `I have forgotten my username and need help accessing my account.`;
      window.Beacon("prefill", {
        subject,
        text,
      });
      window.Beacon("config", {
        mode: "askFirst",
      });
      window.Beacon("event", {
        type: "page-viewed",
        url: document.location.href,
        title: document.title,
      });
      window.Beacon("suggest");
      window.Beacon("open");
    },
  },
};
</script>
