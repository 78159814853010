var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption" }, [_vm._v("Layer Info")]),
      _c(
        "v-card",
        { attrs: { id: "layerInfo" } },
        [
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "caption mb-0 pb-0" }, [
                _vm._v("Layer Type"),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm._f("layerType")(_vm.selectedLayer.service_type))
                ),
              ]),
              _c("validation-observer", { ref: "layerForm" }, [
                _c(
                  "form",
                  [
                    _c("validation-provider", {
                      attrs: { name: "Layer Name", rules: "required" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors, valid }) {
                            return [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Layer Name *",
                                  "hide-details": "auto",
                                  "error-messages": errors,
                                  success: valid,
                                  color: "primary",
                                  name: "serviceName",
                                  disabled: !_vm.canManageLayers,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$emit(
                                      "layer-changed",
                                      _vm.selectedLayer
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedLayer.service_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedLayer,
                                      "service_name",
                                      $$v
                                    )
                                  },
                                  expression: "selectedLayer.service_name",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      { staticStyle: { width: "40%" } },
                      [
                        ["U", "F"].includes(_vm.selectedLayer.service_type)
                          ? _c("validation-provider", {
                              attrs: { name: "Reference Field" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            autocomplete: "off",
                                            clearable: "",
                                            label: "Reference Field",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "refField",
                                            "item-text": "label",
                                            "item-value": "value",
                                            items: _vm.gisDataFieldChoices,
                                            disabled: !_vm.canManageLayers,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$emit(
                                                "layer-changed",
                                                _vm.selectedLayer
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedLayer.ref_field,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedLayer,
                                                "ref_field",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedLayer.ref_field",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2303791508
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "section",
                        { staticClass: "my-2" },
                        [
                          ["U", "F"].includes(_vm.selectedLayer.service_type)
                            ? _c("v-switch", {
                                staticClass: "my-0",
                                attrs: {
                                  label: "Stormwater Enabled",
                                  disabled: !_vm.canManageLayers,
                                  "hide-details": "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$emit(
                                      "layer-changed",
                                      _vm.selectedLayer
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedLayer.stormwater_enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedLayer,
                                      "stormwater_enabled",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedLayer.stormwater_enabled",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "my-2" },
                        [
                          ["U", "F"].includes(_vm.selectedLayer.service_type)
                            ? _c("v-switch", {
                                staticClass: "my-0",
                                attrs: {
                                  label: "Download Layer Data",
                                  disabled: !_vm.canManageLayers,
                                  "hide-details": "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$emit(
                                      "layer-changed",
                                      _vm.selectedLayer
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.selectedLayer.cache_gis_data_values,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedLayer,
                                      "cache_gis_data_values",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedLayer.cache_gis_data_values",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "d-flex align-center mt-2" },
                        [
                          ["U", "F"].includes(_vm.selectedLayer.service_type)
                            ? _c("v-switch", {
                                staticClass: "my-0",
                                attrs: {
                                  label: "Site Enabled",
                                  disabled: !_vm.canManageLayers,
                                  "hide-details": "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$emit(
                                      "layer-changed",
                                      _vm.selectedLayer
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedLayer.site_enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedLayer,
                                      "site_enabled",
                                      $$v
                                    )
                                  },
                                  expression: "selectedLayer.site_enabled",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                right: "",
                                "max-width": "300px",
                                "open-on-click": "",
                                "open-on-hover": false,
                                "open-on-focus": false,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "click-outside",
                                                  rawName: "v-click-outside",
                                                  value: _vm.onClickOutside,
                                                  expression: "onClickOutside",
                                                },
                                              ],
                                              attrs: {
                                                icon: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.closeAllTooltips()
                                                  _vm.showSiteEnabledTooltip = true
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiInformation)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.showSiteEnabledTooltip,
                                callback: function ($$v) {
                                  _vm.showSiteEnabledTooltip = $$v
                                },
                                expression: "showSiteEnabledTooltip",
                              },
                            },
                            [
                              _c("div", [
                                _c("div", [
                                  _vm._v(
                                    " Turn on to make this layer site enabled. With a site enabled layer you can: "
                                  ),
                                ]),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v("Set site name and address"),
                                  ]),
                                  _c("li", [_vm._v("Add site contacts")]),
                                  _c("li", [_vm._v("Create public link")]),
                                  _c("li", [
                                    _vm._v(
                                      "Create site key (use to share site via key)"
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.selectedLayer.site_enabled
                        ? _c(
                            "section",
                            {
                              staticClass: "d-flex align-center w-40",
                              staticStyle: { "margin-left": "47px" },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Site Name Field",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors, valid }) {
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Site Name Field",
                                              "hide-details": "auto",
                                              "error-messages": errors,
                                              success: valid,
                                              color: "primary",
                                              name: "siteNameField",
                                              "item-text": "label",
                                              "item-value": "value",
                                              items: _vm.allDataFieldChoices,
                                              disabled: !_vm.canManageLayers,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$emit(
                                                  "layer-changed",
                                                  _vm.selectedLayer
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectedLayer
                                                  .site_name_field,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedLayer,
                                                  "site_name_field",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedLayer.site_name_field",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3038100554
                                ),
                              }),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    right: "",
                                    "max-width": "300px",
                                    "open-on-click": "",
                                    "open-on-hover": false,
                                    "open-on-focus": false,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "click-outside",
                                                        rawName:
                                                          "v-click-outside",
                                                        value:
                                                          _vm.onClickOutside,
                                                        expression:
                                                          "onClickOutside",
                                                      },
                                                    ],
                                                    attrs: {
                                                      icon: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.closeAllTooltips()
                                                        _vm.showNameFieldTooltip = true
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(_vm.mdiInformation)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    358783293
                                  ),
                                  model: {
                                    value: _vm.showNameFieldTooltip,
                                    callback: function ($$v) {
                                      _vm.showNameFieldTooltip = $$v
                                    },
                                    expression: "showNameFieldTooltip",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " Specify the UtiliSync or GIS field that you want to use for the site name. "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedLayer.site_enabled
                        ? _c(
                            "section",
                            {
                              staticClass: "d-flex align-center w-40",
                              staticStyle: { "margin-left": "47px" },
                            },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Site Address Field" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors, valid }) {
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Site Address Field",
                                              "hide-details": "auto",
                                              "error-messages": errors,
                                              success: valid,
                                              color: "primary",
                                              name: "siteAddressField",
                                              "item-text": "label",
                                              "item-value": "value",
                                              clearable: "",
                                              items: _vm.allDataFieldChoices,
                                              disabled: !_vm.canManageLayers,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$emit(
                                                  "layer-changed",
                                                  _vm.selectedLayer
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectedLayer
                                                  .site_address_field,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedLayer,
                                                  "site_address_field",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedLayer.site_address_field",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2540836606
                                ),
                              }),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    right: "",
                                    "max-width": "300px",
                                    "open-on-click": "",
                                    "open-on-hover": false,
                                    "open-on-focus": false,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "click-outside",
                                                        rawName:
                                                          "v-click-outside",
                                                        value:
                                                          _vm.onClickOutside,
                                                        expression:
                                                          "onClickOutside",
                                                      },
                                                    ],
                                                    attrs: {
                                                      icon: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.closeAllTooltips()
                                                        _vm.showAddressFieldTooltip = true
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(_vm.mdiInformation)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2295297932
                                  ),
                                  model: {
                                    value: _vm.showAddressFieldTooltip,
                                    callback: function ($$v) {
                                      _vm.showAddressFieldTooltip = $$v
                                    },
                                    expression: "showAddressFieldTooltip",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " Specify the UtiliSync or GIS field that you want to use for the site address. "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedLayer.site_enabled
                        ? _c(
                            "section",
                            {
                              staticClass: "d-flex align-center my-2",
                              staticStyle: { "margin-left": "47px" },
                            },
                            [
                              ["U", "F"].includes(
                                _vm.selectedLayer.service_type
                              )
                                ? _c("v-switch", {
                                    staticClass: "my-0",
                                    attrs: {
                                      label: "Auto Generate Site Key",
                                      disabled: !_vm.canManageLayers,
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$emit(
                                          "layer-changed",
                                          _vm.selectedLayer
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.selectedLayer
                                          .auto_generate_short_site_key,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedLayer,
                                          "auto_generate_short_site_key",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedLayer.auto_generate_short_site_key",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    right: "",
                                    "max-width": "300px",
                                    "open-on-click": "",
                                    "open-on-hover": false,
                                    "open-on-focus": false,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "click-outside",
                                                        rawName:
                                                          "v-click-outside",
                                                        value:
                                                          _vm.onClickOutside,
                                                        expression:
                                                          "onClickOutside",
                                                      },
                                                    ],
                                                    attrs: {
                                                      icon: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.closeAllTooltips()
                                                        _vm.showAutoGenerateSiteKeyTooltip = true
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(_vm.mdiInformation)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1961148768
                                  ),
                                  model: {
                                    value: _vm.showAutoGenerateSiteKeyTooltip,
                                    callback: function ($$v) {
                                      _vm.showAutoGenerateSiteKeyTooltip = $$v
                                    },
                                    expression:
                                      "showAutoGenerateSiteKeyTooltip",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " Turn this option on to automatically generate a site key for all existing sites on this layer and create a site key for all new sites created on this layer. "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "section",
                      [
                        _c("div", { staticClass: "caption" }, [
                          _vm._v("Show Related Tab Only"),
                        ]),
                        ["U", "F"].includes(_vm.selectedLayer.service_type)
                          ? _c("v-switch", {
                              attrs: {
                                label: "Inspect App",
                                disabled: !_vm.canManageLayers,
                                "hide-details": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$emit(
                                    "layer-changed",
                                    _vm.selectedLayer
                                  )
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedLayer
                                    .show_related_tab_only_inspect,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedLayer,
                                    "show_related_tab_only_inspect",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedLayer.show_related_tab_only_inspect",
                              },
                            })
                          : _vm._e(),
                        ["U", "F"].includes(_vm.selectedLayer.service_type)
                          ? _c("v-switch", {
                              attrs: {
                                label: "Sites App",
                                disabled: !_vm.canManageLayers,
                                "hide-details": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$emit(
                                    "layer-changed",
                                    _vm.selectedLayer
                                  )
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedLayer.show_related_tab_only_sites,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedLayer,
                                    "show_related_tab_only_sites",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedLayer.show_related_tab_only_sites",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    ["L"].includes(_vm.selectedLayer.service_type)
                      ? _c("validation-provider", {
                          attrs: { name: "811 Code" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        autocomplete: "off",
                                        label: "811 Code",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "account811",
                                        items: _vm.account811Choices,
                                        "item-text": "label",
                                        "item-value": "value",
                                        disabled: !_vm.canManageLayers,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "layer-changed",
                                            _vm.selectedLayer
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.selectedLayer
                                            .locate_request_provider_account_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selectedLayer,
                                            "locate_request_provider_account_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "selectedLayer.locate_request_provider_account_id",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4156706705
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }