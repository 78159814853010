var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showCreateNewPasswordDialog,
        "max-width": "500px",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("validation-observer", {
            ref: "createNewPasswordForm",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ passed }) {
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createNewPassword.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "elevation-0",
                            attrs: { dark: "", color: "primary" },
                          },
                          [
                            _c("v-toolbar-title", [
                              _vm._v("Create New Password"),
                            ]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", dark: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "reset-password-dialog-close"
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          { staticClass: "pb-0" },
                          [
                            _c("p", [
                              _vm._v("Enter and confirm your password"),
                            ]),
                            _c("validation-provider", {
                              attrs: {
                                bails: false,
                                name: "Password",
                                rules: {
                                  hasAtLeastOneUpperChar: true,
                                  hasAtLeastOneLowerChar: true,
                                  hasAtLeastOneNumber: true,
                                  required: true,
                                  passwordMin: { length: 8 },
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({
                                      errors,
                                      valid,
                                      failedRules,
                                    }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Password *",
                                            "hide-details": "auto",
                                            success: valid,
                                            error: errors.length > 0,
                                            name: "password",
                                            type: _vm.showPassword
                                              ? "text"
                                              : "password",
                                            "append-icon": _vm.showPassword
                                              ? _vm.mdiEyeOff
                                              : _vm.mdiEye,
                                          },
                                          on: {
                                            "click:append": function ($event) {
                                              _vm.showPassword =
                                                !_vm.showPassword
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "prepend",
                                                fn: function () {
                                                  return [
                                                    valid
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "rgba(32, 150, 13, 0.76)",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.mdiLock
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c("v-icon", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiLock
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.password,
                                            callback: function ($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password",
                                          },
                                        }),
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "px-11" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pt-2" },
                                                _vm._l(
                                                  _vm.getValidRules(
                                                    failedRules
                                                  ),
                                                  function (rule) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: rule.msg,
                                                        staticClass:
                                                          "caption d-flex pa-0",
                                                        attrs: { cols: "6" },
                                                      },
                                                      [
                                                        rule.status === true &&
                                                        _vm.password.length > 0
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  color:
                                                                    "rgba(32, 150, 13, 0.76)",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.mdiCheck
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "pr-1",
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mdiMinus
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "font-weight-medium",
                                                            class: {
                                                              validRules:
                                                                rule.status &&
                                                                _vm.password
                                                                  .length > 0,
                                                              invalidRules:
                                                                !rule.status ||
                                                                _vm.password
                                                                  .length === 0,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  rule.msg
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("validation-provider", {
                              attrs: {
                                bails: false,
                                name: "confirmPassword",
                                rules: {
                                  passwordsMatch: "@Password",
                                  required: true,
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "pt-4",
                                          attrs: {
                                            "append-icon":
                                              _vm.showConfirmPassword
                                                ? _vm.mdiEyeOff
                                                : _vm.mdiEye,
                                            label: "Confirm Password *",
                                            "hide-details": "auto",
                                            success: valid,
                                            error: errors.length > 0,
                                            name: "confirmPassword",
                                            type: _vm.showConfirmPassword
                                              ? "text"
                                              : "password",
                                          },
                                          on: {
                                            "click:append": function ($event) {
                                              _vm.showConfirmPassword =
                                                !_vm.showConfirmPassword
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "prepend",
                                                fn: function () {
                                                  return [
                                                    valid
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "rgba(32, 150, 13, 0.76)",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.mdiLock
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c("v-icon", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiLock
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.confirmPassword,
                                            callback: function ($$v) {
                                              _vm.confirmPassword = $$v
                                            },
                                            expression: "confirmPassword",
                                          },
                                        }),
                                        [
                                          valid &&
                                          _vm.confirmPassword.length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  key: _vm.msg,
                                                  staticClass:
                                                    "caption validRules pt-2 px-9",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color:
                                                          "rgba(32, 150, 13, 0.76)",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.mdiCheck) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" Passwords match "),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "caption invalidRules pt-2 px-8 font-weight-medium",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        color: "#757575",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.mdiMinus)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" Passwords match "),
                                                ],
                                                1
                                              ),
                                        ],
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "d-flex justify-end pt-n3 pb-3 pr-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  type: "submit",
                                  dark: passed,
                                  disabled: !passed,
                                },
                              },
                              [_vm._v(" Reset Password ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }